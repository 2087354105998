import { json } from "react-router-dom";

const cartname = "cart";

export function addToCart(name){
    var cart = JSON.parse(localStorage.getItem(cartname));
    if(!(cart)) cart=[];
    const index = cart.findIndex(x=>x.name == name);
    if(index !== -1)
        if(cart[index].count < 5)
            cart[index].count++;
        else
            return;
    else{
        cart.push({"name":name, "count":1});
    }
    localStorage.setItem(cartname, JSON.stringify(cart));
}

export function deleteFromCart(name){
    var cart = JSON.parse(localStorage.getItem(cartname));
    if(!(cart)) cart=[];
    const index = cart.findIndex(x=>x.name == name);
    if(index !== -1)
        if(cart[index].count === 1)
            cart.splice(index,1);
        else
            cart[index].count--;
    else{
        return;
    }
    localStorage.setItem(cartname, JSON.stringify(cart));
}

export function deleteProductFromCart(name){
    var cart = JSON.parse(localStorage.getItem(cartname));
    if(!(cart)) cart=[];
    const index = cart.findIndex(x=>x.name == name);
    if(index !== -1)
        cart.splice(index,1);
    else{
        return;
    }
    localStorage.setItem(cartname, JSON.stringify(cart));
}

export function removeOldProducts(data){
    var cart = JSON.parse(localStorage.getItem(cartname));
    var itemsToDelete = [];
    for(var a in cart){
        if(data.findIndex(i=>i.name == cart[a].name) < 0)
            itemsToDelete.push(a);
    }
    for(var a in itemsToDelete){
        deleteProductFromCart(cart[itemsToDelete[a]].name);
    }
    return;
}


export function dropCart(){
    localStorage.removeItem(cartname);
}

export function getTotalCount(){
    var cart = JSON.parse(localStorage.getItem(cartname));
    if(!(cart)) return 0;
    var i = 0;
    for(var a in cart){
        i+= cart[a].count;
    }
    return i;
}

export function getCart(){
    var cart = JSON.parse(localStorage.getItem(cartname));
    return cart;
}


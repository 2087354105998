export default function ChangeArray(data, id, name, value){    
    var update = require('immutability-helper');
    var index = data.findIndex(function(c) { 
        return c.id == id; 
    });
    console.log(index);
    var updated = update(data[index], {[name]: {$set: value}}); 
    
    console.log(updated);
    var newData = update(data, {
        $splice: [[index, 1, updated]]
    });
    
    console.log(newData);
    return newData;
}
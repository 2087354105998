import { useEffect, useState } from "react"
import "../styles/contact.scss"
import { getTireDate } from "../tools/datehelper";
import { useNavigate } from "react-router-dom";
import * as Constants from "../config";
import MainLayout from "../layout";
import uuidv4 from "../tools/guidgenerator";
import Loading from "../components/loading-screen";

export default function Contact(){
    const data = {
        id: uuidv4(),
        name:"",
        email:"",
        message:"",
    };

    const validationErrorData = {
        name:false,
        email:false,
        message:"",
    }

    const navigate = useNavigate();

    const [contactData, setContactData] = useState(data);
    const [sendComplete, setSendComplete] = useState(false);
    const [validationError, setValidationError] = useState(validationErrorData);
    const [hasValidationError, setHasValidationError] = useState(false);
    const [loading, setLoading] = useState(false);

    const onChangeValue = (e) => {        
        const { name, value } = e.target;
        setContactData({...contactData, [name]: value});        
    };

    const goHomePage = () =>{
        navigate("/");
    }

    const validate = () => {
        let haserror = false;
        let validationErrorNew = {};
        if(!contactData.email.match("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"))
        {
            haserror = true;
            validationErrorNew.email = true;
        }
        else
            validationErrorNew.email = false;
        if(contactData.name.length < 2 || contactData.name.length > 30)
        {
            haserror = true;
            validationErrorNew.name = true;
        }
        else
            validationErrorNew.name = false;
        if(contactData.message.length < 5)
        {
            haserror = true;
            validationErrorNew.message = true;
        }
        else
            validationErrorNew.message = false;
        setValidationError(validationErrorNew);
        setHasValidationError(haserror);
        return haserror;
    }

    const saveData = () => {
        if(validate()) return;
        let savedataObj = {};
        setLoading(true);
        Object.assign(savedataObj,contactData);
        savedataObj.date = getTireDate(new Date());
        const body = Constants.requestBody(savedataObj);
        fetch(Constants.apiurl + Constants.contactUsPath, body)
        .then(response => {
            setSendComplete(true);
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
        )
        .catch(err => {
            console.log(err.error);
            setTimeout(() => {
                setLoading(false);
            }, 500);
            alert("Something went wrong. Try again later.")
        });
    }

    useEffect(() => {
        if(hasValidationError) validate();
     }, [contactData]);

    const goBackButtons=()=>{
        return(
            <div className="confirm-border">
                <img src="home_button.png" className="confirmation-page-logo" />
                <div className="confirmation-header">
                    Thank you for contacting us.                    
                    <div>
                    Message has been sent successfully.
                    </div>
                </div>
                <div className="text-center confirmation-text">
                    <div className="japanese-confirmation-text">
                     </div>
                    <div >
                    </div>
                </div>
                <div className="go-back-buttons">
                    <button onClick={goHomePage} className="button go-back-buttons-text">GO HOME PAGE</button>
                </div>
            </div>
        )
    }

    const contactHeader = () =>{
        return(
                <div className="header-text">
                    CONTACT
                </div>  
        )
    }

    const contactHTML =()=>{
        return(
            <>
                <div className="reservation-inputs">
                    <label >Name 氏名</label>
                    <label style={{display: validationError.name ? "block" : "none"}} className="red-text">Name should contain from 2 to 30 characters</label>
                    <input autoComplete="off" onChange={onChangeValue} name="name" className="reservation-input" required maxLength="30" placeholder="例）ブイカス" value={contactData.name} />
                    <label >Email</label>
                    <label style={{display: validationError.email ? "block" : "none"}} className="red-text">Please, input correct email</label>
                    <input autoComplete="off" onChange={onChangeValue}  name="email" className="reservation-input"  required maxLength="50" placeholder="例) example@mail.com" type="email" value={contactData.email} />
                    <label >Message ご質問 • ご不明な点など</label>
                    <label style={{display: validationError.message ? "block" : "none"}} className="red-text">Message should contain at least 5 characters</label>
                    <textarea onChange={onChangeValue} name="message" className="reservation-input reservation-textarea"  placeholder="Message(optional)" maxLength="700" value={contactData.message} />
                    <button onClick={saveData} className="button">SEND</button>
                </div>                
            </>
        )
    }

    return(
        <MainLayout>
            <div style={{display:loading ? "" : "none"}}>
                <Loading />
            </div>
            <div className="contacts">
                <div className="resize-block">
                    {sendComplete ? "" : contactHeader()}          
                    <div className="body-block">
                        {sendComplete ? goBackButtons() : contactHTML()}
                    </div>
                </div>
            </div>
        </MainLayout>
    )
    
}
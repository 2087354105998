import { isMobile } from "react-device-detect";
import Bottle from "../components/bottle";
import MainLayout from "../layout";

function MainPage(){
    
    const placebottleStyle = {
        marginTop: isMobile ? "70px" : "20px",
    }

    return(
        <MainLayout>
            <div style={placebottleStyle}>
                <Bottle/>
                хуй
            </div>
        </MainLayout>
    )
}

export default MainPage;
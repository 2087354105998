import { useEffect, useState } from "react"
import { useSwipeable } from 'react-swipeable';

export default function Slider(props){

    const [currentImage, setCurrentImage] = useState(0);
    const [imageClassName, setImageClassName] = useState("main-slider-image");
    const [imageKey, setImageKey] = useState(0 + "slider-image");

    function getPreviousIndex(){
        if(props.images?.length)
            if(currentImage - 1 < 0)
                return props.images.length - 1;
            else
                return currentImage - 1;
    }

    function slideLeft(){        
        setImageClassName("main-slider-image slider-left-animate");
        setCurrentImage(getNextIndex());        
    }

    function slideRight(){        
        setImageClassName("main-slider-image slider-right-animate");
        setCurrentImage(getPreviousIndex());        
    }

    useEffect(() => {
        setImageKey(currentImage + "slider-image")
     }, [currentImage]);

    function getNextIndex(){
        if(props.images?.length)
            if(currentImage + 1 >= props.images.length)
                return 0;
            else
                return currentImage + 1;
    }

    const handlers = useSwipeable({
        onSwipedLeft: () => slideLeft(),
        onSwipedRight: () => slideRight(),
        swipeDuration: 500,
        preventScrollOnSwipe: true,
        trackMouse: true
      });

    return(
        <>
            <div {...handlers} className="slider">
                    <img className="arrow-slider left-arrow" src="left-arrow-svgrepo-com.svg" onClick={slideRight}/>
                    {/* <img src={!!(props.images) ? props.images[getPreviousIndex(currentImage)] : ""}/> */}
                    <img key={imageKey} onClick={()=>props.enlarge( !!(props.images) ? props.images[currentImage] : "")}  className={imageClassName} src={!!(props.images) ? props.images[currentImage]: ""}/>    
                    <img key={imageKey+"1"}  style={{display:"none"}} src={!!(props.images) ? props.images[currentImage-1]: ""}/>    
                    <img key={imageKey+"2"}  style={{display:"none"}} src={!!(props.images) ? props.images[currentImage+1]: ""}/>    
                    <img className="arrow-slider right-arrow" src="right-arrow-svgrepo-com.svg" onClick={slideLeft}/>        
                    {/* <img src={!!(props.images) ? props.images[getNextIndex(currentImage)]: ""}/> */}
            </div>
        </>
    )
}
import { Button, TextField } from "@mui/material";
import Card from "./card";
import ChangesButtons from "./changesbuttons";
import { useEffect, useState } from "react";
import ChangeArray from "../tools/arrayHelper";
import ImageUploader from "./imageuploader";
import * as Constants from "../config";
import { Dataset } from "@mui/icons-material";
import uuidv4 from "../tools/guidgenerator";
import Jodit from "./jodit";

export default function MerchConfig(){
    
    const data = [{
        id:345434534,
        name: "2024-03-04",
        image: ["/poster/202338live.png"],
        price: "2777",
        description1:"shinjuku antiknock",
        description2:"shinjuku antiknock",
        waribiki:0,
        haitatsu:0,
    }
];
    
    const [currentNewsText, setCurrentNewsText] = useState();
    const [currentNewsProperty, setCurrentNewsProperty] = useState();
    const [currentNewsId, setCurrentNewsId] = useState();
    const [dataState, setDataState] = useState(data);

    const [uploadPropertyState, setUploadPropertyState] = useState("");

    const [uploaderActiveState, setUploaderActiveState] = useState(false);
   
    const [selectedImage, setSelectedImage] = useState("");

    const deleteData = (id) =>{
        if (window.confirm('Are you sure you want to delete?')) {
            fetch(Constants.apiurl + Constants.deleteMerchPath + "?id=" +id, Constants.requestHeaderOnly())
            .then((response) => getData())
            .catch((err) => {
               console.log(err.message);
            });
        }
    };
    
    const addData = () =>{
        let newid = uuidv4();
        const newElement = {id:newid,name:"merch name", image:[]};
        const newData = [newElement].concat(dataState);
        console.log(newData);
        setDataState(newData);
    };

    const onChangeValue = (e) => {
        const { id, name, value } = e.target;
        setDataState(ChangeArray(dataState, id, name, value));     
    };


    const imageSelected = (filename) => {
        if(filename !== "" && filename !== undefined){
            var imagesArray = [filename];
            if(!!(uploadPropertyState.image)){
                imagesArray = [filename,...uploadPropertyState.image];
            }
            var e = {
                target:{
                    id:uploadPropertyState.id,
                    name:"image",
                    value: imagesArray
                }
            };
            onChangeValue(e);
        }
        setUploaderActiveState(false);
    };

    const deleteImage = (id,name) => {
        var array = dataState.find(x=>x.id==id).image;
        const index = array.indexOf(name);
        if (index > -1) { 
            array.splice(index, 1); 
        }
        var e = {
            target:{
                id:id,
                name:"image",
                value: array
            }
        };
        onChangeValue(e);
    }

    const selectImage = (item) => {
        setUploadPropertyState(item);
        setUploaderActiveState(true);
    };

    const getData = () => {
        fetch(Constants.apiurl + Constants.merchPath)
        .then((response) => response.json())
        .then((data) => {
            setDataState(data);
        })
        .catch((err) => {
           console.log(err.message);
        });
    }

    const saveData = (data) => {
        const body = Constants.requestBody(data);
        fetch(Constants.apiurl + Constants.merchPath, body)
        .then(response => {
            if(response.status == 401) window.location.reload(false);
            return response.json();
        })
        .then(data => {
            getData();
        })
        .catch(err => {
            console.log(err.error);
        });
    }

    useEffect(() => {
        getData();
     }, []);

    const imagesList = (i,id) => {
        return(
        <>
            <li>{i}</li>
            <img src="cross.svg" onClick={()=>deleteImage(id,i)}/>
        </>
        )
    }

    const inputsHtml = (item) => {
        return(
            <div key={item.id+"merchexpanded"}>
                <div className="input-block">
                    <div className="input-title">Name:</div>
                    <input id={item.id} onChange={onChangeValue} name="name" label="name" variant="outlined" value={item.name} />
                </div>
                <div className="input-block">
                    <div className="input-title">Images:</div>
                    <div className="images-list">
                        {item.image.map(i=> imagesList(i,item.id))}
                    </div>
                    <button id={item.id} onClick={()=>selectImage(item)} name="poster" label="poster" variant="outlined">Add Image</button>
                </div>
                <div className="input-block">
                    <div className="input-title">Price:</div>
                    <input id={item.id}  onChange={onChangeValue} name="price" label="price" variant="outlined" value={item.price} />
                </div>
                <div className="input-block">
                    <div className="input-title">Waribiki:</div>
                    <input id={item.id}  onChange={onChangeValue} name="waribiki" label="waribiki" variant="outlined" value={item.waribiki}  />
                </div>
                <div className="input-block">
                    <div className="input-title">Haitatsu:</div>
                    <input id={item.id}  onChange={onChangeValue} name="haitatsu" label="haitatsu" variant="outlined" value={item.haitatsu}  />
                </div>
                <div className="input-block">
                    <div className="input-title">Description 1:</div>
                    <input onClick={()=>openTextEditor(item.id,item.description1,"description1")} className="pointer" id={item.id} name="text" label="text" variant="outlined" readOnly />
                </div>
                <div className="input-block">
                    <div className="input-title">Description 2:</div>
                    <input onClick={()=>openTextEditor(item.id,item.description2,"description2")} className="pointer" id={item.id} name="text" label="text" variant="outlined" readOnly />
                </div>
                <Button style={{margin:"10px", width:"100px"}} onClick={()=>saveData(item)} variant="contained" color="success">保存</Button>
            </div>
        )
    };

    const inputsHtmlClosed = (id,name) =>{
        return(
            <div key={id+"merchclosed"}>
                <div>{name}</div>
            </div>
        )
    }
    
    const openTextEditor = (id, text, prop)=>
    {
        setCurrentNewsId(id);
        setCurrentNewsText(text);
        setCurrentNewsProperty(prop);
    }

    const textComplete = ()=>
    {
        setDataState(ChangeArray(dataState, currentNewsId, currentNewsProperty, currentNewsText)); 
        setCurrentNewsId("");
        setCurrentNewsText("");
        setCurrentNewsProperty("");
    }

    const setTextContent = (text) =>{
        setCurrentNewsText(text);
    }

    const modaltexteditor = () =>{
        return(            
            <div className="modal-text-editor">
                <Jodit textComplete={textComplete} setTextContent={setTextContent} content={currentNewsText} id={currentNewsId}  />
            </div>
        )
    }

    const dataHtml = dataState.map(item=> 
            <Card key={item.id + item.image} 
            child={inputsHtml(item)} 
            childClosed={inputsHtmlClosed(item.id,item.name)} 
            id={item.id} 
            expandable={false}
            handleDelete={deleteData} />
        )


    return(
        <>
            {!!(currentNewsId) ? modaltexteditor():""}
            <ImageUploader selectedImage={selectedImage} handleChoose={imageSelected} active={uploaderActiveState}/>
            <div>
                <ChangesButtons saveAction={saveData} revertAction={getData} addAction={addData} />
            </div>
            {dataHtml}
        </>
    )
}

import { useEffect, useState } from "react";
import * as Constants from "../config";
import MainLayout from "../layout";
import "../styles/merch.scss"
import AwesomeSlider from 'react-awesome-slider'
import 'react-awesome-slider/dist/styles.css';
import Slider from "../components/slider";
import parse from 'html-react-parser'
import { addToCart, getTotalCount } from "../tools/cartservice";
import CartElement from "../components/cart-element";

export default function Merch(){

    const data = [{
        id:0,
        name:"123",
        images:[]
    }];

    const [dataState,setDataState] = useState(data);
    const [goodsCount,setGoodsCount] = useState(0);

    const [enlargeImageSource, setEnlargeImageSource] = useState("");

    const enlargeImageBlock = {
        display: !!(enlargeImageSource) ? "" : "none"
    }

    async function getData() {
        fetch(Constants.apiurl + Constants.merchPath)
        .then((response) => response.json())
        .then((data) => {
            setDataState(data);
        })
        .catch((err) => {
           console.log(err.message);
        });
    };


    useEffect(() => {
        getData();
        setGoodsCount(getTotalCount());
     }, []);

    const merch = (item) => {
        return(
            <div className="merch-block">
                <div className="merch-images-block">
                    <Slider enlarge={setEnlargeImageSource} images={item.image} />
                </div>
                <div className="main-bg-color merch-info-block">
                     <img id="waribiki" src="waribiki.png"/>
                    <div id="title">
                        <div>
                            {item.name + " ¥" + item.price} 
                        </div>
                        <div className="zeicomi-small-text">
                        ( 税込 )
                        </div>
                    </div>   
                    <div id="description">
                        <div id="left-description">
                            {parse(!!(item.description1) ? item.description1 : "")}
                        </div>
                    </div>    
                    <div id="description">
                        {parse(!!(item.description2) ? item.description2 : "")}
                    </div>   
                    <div className="button-block">
                        <div className="button" onClick={()=>{
                            addToCart(item.name);
                            setGoodsCount(getTotalCount());
                            }}>
                            <div>
                                <div className="small-text">カートに追加</div>
                                <div>ADD TO CART</div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        )
    }
    
    const merchlist = dataState.map(item=> merch(item));

    return(
        <MainLayout>
            <div onClick={()=>setEnlargeImageSource("")} style={enlargeImageBlock} className="modal">    
                <img className="enlarge-image" src={enlargeImageSource}/>  
            </div>
            <div className="merch-page-cart">
                <CartElement count={goodsCount}/>
            </div>
            <div className="merch-content">
                {merchlist}
            </div>
        </MainLayout>
    )
}
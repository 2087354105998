import { useState } from "react"
import "../styles/loading.scss"


export default function Loading(){

    const [bg,setBg] = useState();
    const [rotate,setRotate] = useState();    

    return(
        <div className="loading-screen">
            <img  src="handlogo2.png"/>
        </div>
    )
}
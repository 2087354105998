import { Button, TextField } from "@mui/material";
import Card from "./card";
import ChangesButtons from "./changesbuttons";
import { useEffect, useState } from "react";
import ChangeArray from "../tools/arrayHelper";
import ImageUploader from "./imageuploader";
import * as Constants from "../config";
import { Dataset } from "@mui/icons-material";
import uuidv4 from "../tools/guidgenerator";

export default function LiveConfig(){
    
    const data = [{
        id:0,
        date: "2024-03-04",
        time: "",
        poster: "/poster/202338live.png",
        livename: "A Ghost of Flare & antiknock pre. [nevacoom vol.9]",
        place:"shinjuku antiknock",
        price: "adv Y3000+1D / DOOR Y3500",
        buylink:"http://123"
    }
];
    
    const [dataState, setDataState] = useState(data);

    const [uploadPropertyState, setUploadPropertyState] = useState("");

    const [uploaderActiveState, setUploaderActiveState] = useState(false);
   
    const [selectedImage, setSelectedImage] = useState("");

    const deleteData = (id) =>{
        if (window.confirm('Are you sure you want to delete?')) {
            fetch(Constants.apiurl + Constants.deleteLivePath + "?id=" +id, Constants.requestHeaderOnly())
            .then((response) => getData())
            .catch((err) => {
               console.log(err.message);
            });
        }
    };
    
    const addData = () =>{
        let newid = uuidv4();
        const newElement = {id:newid,livename:"new event",date:"2024-03-25"};
        const newData = [newElement].concat(dataState);
        console.log(newData);
        setDataState(newData);
    };

    const onChangeValue = (e) => {
        const { id, name, value } = e.target;
        setDataState(ChangeArray(dataState, id, name, value));     
    };


    const imageSelected = (filename) => {
        if(filename !== "" && filename !== undefined){
            var e = {
                target:{
                    id:uploadPropertyState.id,
                    name:uploadPropertyState.name,
                    value:filename
                }
            };
            onChangeValue(e);
        }
        setUploaderActiveState(false);
    };

    const selectImage = (e) => {
        const newE = e.target;
        setSelectedImage(e.target.value);
        setUploadPropertyState(newE);
        setUploaderActiveState(true);
    };

    const getData = () => {
        fetch(Constants.apiurl + Constants.getlivesPath)
        .then((response) => response.json())
        .then((data) => {
            setDataState(data);
        })
        .catch((err) => {
           console.log(err.message);
        });
    }

    const saveData = (data) => {
        const body = Constants.requestBody(data);
        fetch(Constants.apiurl + Constants.setlivesPath, body)
        .then(response => {
            if(response.status == 401) window.location.reload(false);
            return response.json();
        })
        .then(data => {
            getData();
        })
        .catch(err => {
            console.log(err.error);
        });
    }

    useEffect(() => {
        getData();
     }, []);

    const inputsHtml = (id,date,time,poster,livename,place,price,buylink,item) => {
        return(
            <div key={id+"livexpanded"}>
                <div className="input-block">
                    <div className="input-title">Date:</div>
                    <input id={id} type="date" onChange={onChangeValue} name="date" label="date" variant="outlined" value={date} />
                </div>
                <div className="input-block">
                    <div className="input-title">Time:</div>
                    <input id={id} onChange={onChangeValue} name="time" label="time" variant="outlined" value={time} />
                </div>
                <div className="input-block">
                    <div className="input-title">Poster:</div>
                    <input title={poster} id={id} style={{"cursor":"pointer"}} onClick={selectImage} name="poster" readOnly label="poster" variant="outlined" value={poster} />
                </div>
                <div className="input-block">
                    <div className="input-title">Live Name:</div>
                    <input id={id}  onChange={onChangeValue} name="livename" label="livename" variant="outlined" value={livename} />
                </div>
                <div className="input-block">
                    <div className="input-title">Place:</div>
                    <input id={id}  onChange={onChangeValue} name="place" label="place" variant="outlined" value={place} />
                </div>
                <div className="input-block">
                    <div className="input-title">Price:</div>
                    <input id={id}  onChange={onChangeValue} name="price" label="price" variant="outlined" value={price} />
                </div>
                <div className="input-block">
                    <div className="input-title">Buy link:</div>
                    <input id={id}  onChange={onChangeValue} name="buylink" label="buylink" variant="outlined" value={buylink} />
                </div>
                <Button style={{margin:"10px", width:"100px"}} onClick={()=>saveData(item)} variant="contained" color="success">保存</Button>
            </div>
        )
    };

    const inputsHtmlClosed = (id,date,livename) =>{
        return(
            <div key={id+"liveclosed"}>
                <div>{date}</div>
                <div className="livename-overflow-hide">{livename}</div>
            </div>
        )
    }

    const dataHtml = dataState.map(item=> 
            <Card key={item.id + item.image} 
            child={inputsHtml(item.id,item.date,item.time,item.poster,item.livename,item.place,item.price,item.buylink,item)} 
            childClosed={inputsHtmlClosed(item.id,item.date,item.livename)} 
            id={item.id} 
            expandable={true}
            handleDelete={deleteData} />
        )


    return(
        <>
            <ImageUploader selectedImage={selectedImage} handleChoose={imageSelected} active={uploaderActiveState}/>
            <div>
                <ChangesButtons saveAction={saveData} revertAction={getData} addAction={addData} />
            </div>
            {dataHtml}
        </>
    )
}
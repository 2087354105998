import React from 'react';
import { useNavigate } from 'react-router-dom';
import {useState, useEffect} from 'react';

function BombButton(props) {
 
  const [moveX, setMoveX] = useState(3);
  const [moveY, setMoveY] = useState(5);
  const textstyle = {
    textAlign: "center",
    verticalAlign: "middle",
    lineHeight: props.mousovered===props.name ? props.size + "px" : props.size + "px",
    transition: "all 0.5s",
    fontSize: props.size/6
  }

  const style = {
    //background:"radial-gradient(rgb(0, 0, 0) 65%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 0) 100%)",
    borderRadius:"50%",      
    backgroundImage:"url(button.png)",
    backgroundSize: "cover",
    backgroundRepeat: "round",      
    color:"white",
    height: props.size + "px",
    width: props.size +  "px",
    marginTop: props.mousovered===props.name ? "-" + props.size/4 + "px" : "0px",    
    marginLeft: props.mousovered===props.name ? "-" + props.size/4 + "px" : "0px",
    transition: "all 0.5s",
    transform: "scale(" + props.scale + ")",
    cursor: "default",
  }

  // const button = {
  //   backgroundImage:"url(button.png)",
  //   backgroundSize: "cover",
  //   backgroundRepeat: "round",    
  //   height: props.size - 4 + "px",
  //   width: props.size - 4 +  "px",
  // }

  const movestyle = {
    transition: "margin-top 2s, margin-left 3s",
    transitionDelay: "1.5s",
    marginTop: moveY,
    marginLeft: moveX,
  }

  const shadow = {
    marginLeft: props.mousovered===props.name ? -props.size: -props.size*0.75,
    marginTop: - props.size*0.75,
    height: props.size + props.size*1.5,
    width: props.size + props.size*1.5,
    position:"absolute",
    transition: "all 0.5s"
  }

  setTimeout(() => {
    setMoveX(-moveX);
  }, 3000);
  setTimeout(() => {
    setMoveY(-moveY);
  }, 2000); 

//   useEffect(() => {
//     setTimeout(() => {
//       setMoveX(-moveX);
//     }, 3000);
//     setTimeout(() => {
//       setMoveY(-moveY);
//     }, 2000);    
//  }, []);

  return (
    <div style={movestyle}>
       {/* <img style={shadow} src={props.shadow}/> */}
      <div style={style} id={props.id} className={props.shadowclass} >  
        {/* <div style={button}>      */}        
          <div style={textstyle}>{props.name}</div> 
          
      </div>
    </div>
  );
}

export default BombButton;
import { useEffect, useState } from "react";
import NewsCard from "../components/newscard";
import MainLayout from "../layout";
import * as Constants from "../config";
import {getDateFormat, getSlashDate} from "../tools/datehelper";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function News(){

    const navigate = useNavigate();

    const data = [{"id":4,
    "image":"",
    "text":"asd",
    "date":new Date(),
    "type":"INFORMATION",
    "title":"sdasd"}];

    const [dataState, setDataState] = useState(data);
    const [displayLoading, setDisplayLoading] = useState(false);
    const [showSingleNews, setShowSingleNews] = useState("");

    const [searchParams, setSearchParams] = useSearchParams();
    const displayUnpublished = searchParams.get("type") == "unpublished";
    
    async function getData() {
        fetch(Constants.apiurl + Constants.newsPath)
        .then((response) => response.json())
        .then((data) => {
            data.forEach(item=>item.date=getDateFormat(item.date));
            data.sort(function(a,b){
                return new Date(b.date) - new Date(a.date);
               });
            setDataState(data);
        })
        .catch((err) => {
           console.log(err.message);
        });
    }

    useEffect(() => {
        getData();
     }, []);

    const openNews =(item)=>{
        if(item.type=="LIVE")
            navigate("/live#" + item.title);
        setShowSingleNews(item);      
        setDisplayLoading(true);  
    }

    const newsHTML = dataState.filter(x=>!!(x.publish) == !displayUnpublished).map(item=> 
        <div key={item.title} onClick={()=>openNews(item)} className="news-list">
            <div>
                {item.type}
            </div>
            <div>
                {getSlashDate(item.date)}
            </div>
            <div>
                {item.title}
            </div>
        </div>        
    )

    const singleNewsHTML = () =>{
        return(
            <NewsCard 
            showNews={showNews}
            title={showSingleNews.title} 
            text={showSingleNews.text} />
        )
    }

    
    const showNews = () =>{
        setShowSingleNews("");
        setDisplayLoading(false);
    }

    const newsblockHTML = () =>{
        return(
            <div className="news-block">
                <div className="resize-block">
                    <div className="header-text">
                        NEWS
                    </div>  
                    <div className="news-body-block">
                            {newsHTML}
                    </div>
                </div>
            </div>   
        )
    }

    return(
        <MainLayout displayLoading={displayLoading}>                        
             {!!(showSingleNews) ? singleNewsHTML() : newsblockHTML()}      
        </MainLayout>
    )
}
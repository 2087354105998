import './App.css';
import './styles/news.scss';
import MainLayout from './layout';
import Admin from './pages/admin';
import Live from './pages/live';
import MainPage from './pages/main';
import { Routes, Route } from "react-router-dom";
import News from './pages/news';
import Biography from './pages/biography';
import Contact from './pages/contact';
import { useEffect, useState } from 'react';
import Merch from './pages/merch';
import Cart from './pages/cart';
import Music from './pages/music';
import ReactGA from 'react-ga4';

 
ReactGA.initialize('G-6KDGDFEFQS');

function App() {
  
  const musicList = [
    {
        music:"",
        picture:"radio_off.png"
    },
    {
        music:"1.MP3",
        picture:"radio_01.png"
    },
    {
        music:"2.MP3",
        picture:"radio_02.png"
    },
    {
        music:"3.mp3",
        picture:"radio_03.png"
    }
];

  const [currentInPlay,setCurrentInPlay] = useState(0);
  const [player,setPlayer] = useState(new Audio());
  function playMusic(){
    let index = 0;
    if(currentInPlay + 1 == musicList.length)
        index = 0;
    else
        index = currentInPlay + 1;        
    if(player != null )player.pause();
    if(index == 0)
    {
        setPlayer(null);
    }
    else{
        const p = new Audio(musicList[index].music)        
        p.play();
        p.loop=true;
        setPlayer(p);
    }
    setCurrentInPlay(index);
}
useEffect(() => {
  window.scrollTo(0,1);
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
}, []);
  return (
    <div>  
      <div style={{display:document.location.pathname == "/admin" ? "none" : "flex"}} onClick={playMusic} className='radio'>
        <img   src={musicList[currentInPlay].picture}/>
        {/* <div style={{display:currentInPlay>0 ? "none" : ""}}>PRESS TO PLAY</div>  */}
      </div>
      <Routes>
          <Route path="/" element={<MainPage />}/>
          <Route path="/live" element={<Live />}/>          
          <Route path="/admin" element={<Admin />}/>
          <Route path="/news" element={<News />}/>
          <Route path="/bio" element={<Biography />}/>
          <Route path="/merch" element={<Merch />}/>
          <Route path="/contact" element={<Contact />}/>
          <Route path="/cart" element={<Cart />}/>
          <Route path="/music" element={<Music />}/>
      </Routes>  
    </div>
    
  );
}

export default App;

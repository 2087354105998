import { Link } from "react-router-dom";

function SocialLink(props){

    const link = {
        backgroundImage:"url(" + props.imgurl + ")",
        height: props.height + "px",
        width: props.width + "px",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
    }

    const style = {
        height: props.height + "px",
        width: props.width + "px",
        display:"inline-block",
        paddingRight: props.width/0.7 + "px",        
    }
    
    return (
        <div style={style}>
            <Link to={props.link} >
                <div style={link}></div>
            </Link>
        </div>
    )
}

export default SocialLink;
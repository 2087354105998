import { HighlightOffOutlined } from "@mui/icons-material";
import { useState } from "react";

export default function Card(props){
    
    const [expanded,setExpanded] = useState(!props.expandable);


    const cardStyle = {
        backgroundColor: !!(props.backgroundColor) ? props.backgroundColor : "#343434",
        padding:"20px",
        margin:"10px",
        border:"solid 1px #b1b1b1",
        borderRadius:"4px",
        position:"relative",
        display:"inline-block",
        color:"white",
        maxWidth:"300px"
    };

    const deleteIcon = {
        position:"absolute",
        top:"5px",
        right:"5px",
        cursor:"pointer",
        display: props.handleDelete === undefined ? "none" : ""
    };

    const inputsStyle = {
        minWidth:"200px",
        display: "inline-block"
    };    

    const expandArea = {
        minWidth:"160px",
        maxWidth:"200px",
        display: "block",
        height:"40px",
        marginBottom:"10px",
        backgroundColor:"#565656",
        borderRadius:"10px"
    };    

    const iconStyle={
        fontSize:"40px"
    };

    const expand = () =>{
        if(!!(props.childClosed))
            setExpanded(!expanded);
    }

    return(
        <div style={cardStyle}>
            <div style={expandArea} onClick={expand}>                
            </div>
            <div style={inputsStyle} >
                {expanded ? props.child : props.childClosed}
            </div>
            <div style={deleteIcon} id={props.id}  onClick={() => props.handleDelete(props.id)}>
                <HighlightOffOutlined style={iconStyle}/>
            </div>
        </div>
    )
}
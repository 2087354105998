import { useState, useEffect } from 'react';
import useWindowDimensions from './tools/window_resolution';
import TopMenu from './components/top_menu';
import SocialLink from './components/social';
import { isMobile } from 'react-device-detect';
import Loading from './components/loading-screen';
import { useNavigate } from 'react-router-dom';


function MainLayout(props){

    const imageWidth = 2500/2.5;
    const imageHeight = 3508/2.5;

    const navigate = useNavigate();

    const [loading,setLoading] = useState(true);
    const [displayContent,setdisplayContent] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [radioImg, setRadioImg] = useState("radio_01.png");

    const socialLinks = [
        {
            imgurl:"ig_icon.png",
            link:"https://www.instagram.com/nabla9.tokyo/"
        },
        {
            imgurl:"x_icon.png",
            link:"https://twitter.com/NABLA9_Tokyo"
        },
        {
            imgurl:"fb_icon.png",
            link:"https://www.facebook.com/nabla9.official/"
        },
        {
            imgurl:"line.png",
            link:"https://lin.ee/DXcIQuA"
        },
        {
            imgurl:"youtube_icon.png",
            link:"https://www.youtube.com/channel/UCwp1xFSMSSLrSrFEyQJifKQ"
        },
        {
            imgurl:"spotify_icon.png",
            link:"https://open.spotify.com/artist/0fR69VNfdfW1QclKe6pfM6"
        },
        {
            imgurl:"apple_icon.png",
            link:"https://music.apple.com/jp/artist/nabla9/1720055094"
        },
    ];

    const [width,setWidth] = useState(imageWidth);
    const [height,setHeight] = useState(imageHeight);
    const initialwidth = useWindowDimensions().width;
    const screenheight = useWindowDimensions().height;
    const screenwidth = initialwidth  < 375 ? 375 : initialwidth;
    const [leftmargin,setLeftmargin] = useState((screenwidth - imageWidth)/2);
    
    const iconSize = width/25 < 25 ? 20 : 30;
    
    const style = {
        position:"fixed",
        zIndex: "-2",
        top:0,
        left:"50%",
        transform: "translate(-50%, 0)",
        maxWidth:"1000px",
        width:"120%"
    }    

    const [bgImage,setBgImage] = useState("bg-small.png");

    const parentstyle = {
        backgroundColor:"black",
        width: screenwidth,
        height: screenheight,
        position:"fixed",
        zIndex: "-3"
    }

    const stylecontent = {
        paddingTop: isMobile ? screenheight/8 : screenheight/20,
        display: "flex",
        justifyContent: "center", 
    }

    const topmenu = {
        position:"absolute",
        right: isMobile? "22px" : width < 600 ? "22px" : leftmargin + 80,
        width: isMobile? "60px" : "100px",
        cursor:"pointer"
    }

    const topmenuopened = {
        position:"absolute",
        right: width < 600 ? "20px" : leftmargin + 50,
        top: "-10px"
    }

    const logo = {
        position:"absolute",
        left: "50%",
        transform: "translate(-50%,0)",
        height:isMobile? "15px" : "20px",
        width:isMobile? "80px" : "100px",
    }

    const ruchki = {
        position:"absolute",
        height: width/5 > 120 ? 120 : width/7,
        width: width/5 > 120 ? 120 : width/7,
        cursor:"pointer"
    }

    const ruchkidiv = {
        position:"absolute",
        left: leftmargin + width/20,
        height: width/5 > 120 ? 120 : width/6,
        width: width/5 > 120 ? 120 : width/6,
    }

    const header = {
        top: isMobile ? "50px":"70px"
    }
    
    
    function CalculateWidth(){
        if(screenwidth > imageWidth){
            setWidth(imageWidth);
            setLeftmargin((screenwidth - imageWidth)/2);
        }
        else{
            setWidth(screenwidth);
            setLeftmargin(0);
        }
        console.log(leftmargin);
    }

    const displayLoading = () =>{
        setLoading(true);
        setdisplayContent(true);
        let timeout = 600;
        if(document.location.pathname == "/")
            timeout=2000;
        setTimeout(() => {
            setLoading(false);
        }, timeout);
    }


    useEffect(() => {
        const img = new Image();
        img.src = "bg.png"; 
        img.onload = changeImage;
      }, []);

    const changeImage = () =>{
        console.log(bgImage);
        setBgImage("bg.png");
    }

    useEffect(() => {
        CalculateWidth();
      }, [screenwidth]);

    useEffect(() => {
        displayLoading();
      }, [props.displayLoading]);

    const loadingHTML = () => {
        return(
            <>
            <div className="loading-screen">
                <img  src="handlogo2.png"/>
            </div>
            </>
        )
    }

    const socialLinksList = socialLinks.map(item => 
        <SocialLink 
            key={item.imgurl} 
            imgurl={item.imgurl} 
            link={item.link}
            height={iconSize}
            width={iconSize}
        />
    );

    return(
        <div>
            {loading?
                    loadingHTML()
            :""
            }
            
            <div>
                <div style={parentstyle}>
                </div>            
                <div style = {header} className='header-elements header-elements-blend'>
                    <div style={ruchkidiv}>
                        <img onClick={()=>navigate("/")} src='home_button.png' style={ruchki}/>
                        </div>
                        <img style={logo} src='text_logo.png'/>
                        <img onClick={() => {
                            setShowMenu(!showMenu);
                            }}
                            style={topmenu} src='menu_icon.png'/>
                </div>      
                <div style = {header} className='header-elements'>
                        <div style={topmenuopened}>
                                    <TopMenu closeMenu={setShowMenu} showMenu={showMenu}/>
                        </div>
                </div>
                <div style={{position:"relative", 
                            display:"contents"}}> 
                    <div className='social-link-block'>
                        {socialLinksList}
                    </div>
                    <img style={style} src={bgImage}/> 
                    <div style={stylecontent}>
                        { props.children }
                    </div>

                </div>
            </div>

        </div>
    )
}

export default MainLayout;
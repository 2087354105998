export function getDateFormat(date){
    return new Date(
        date.split('-')[0],
        date.split('-')[1]-1,
        date.split('-')[2]
    )
}

export function getSlashDate(date){
    return date.getFullYear() + " / " + (date.getMonth() + 1) + " / " + date.getDate();
}

export function getTireDate(date){
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
}
export const apiurl = "";//process.env.REACT_APP_WEB_API_URL;
export const imagesUrl = apiurl + "images/";
export const getlivesPath = "getlives";
export const setlivesPath = "setlives";
export const createReservationPath = "createreservation";
export const merchPath = "getmerch";
export const deleteMerchPath = "deletemerch";
export const reservationsPath = "reservations";
export const deleteLivePath = "deletelive";
export const newsPath = "getnews";
export const setnewsPath = "setnews";
export const deletenewsPath = "deletenews";
export const imageUpload = "uploadimage";
export const imagesListPath = "imageslist";
export const deleteimagePath = "deleteimage";
export const createMerchOrder = "createmerchorder";
export const getMerchOrders = "getmerchorders";
export const contactUsPath = "contactus"
export const archivemessage = "archivemessage"
export const archivereservation = "archivereservation"
export const archiveorder = "archiveorder"
export const loginPath = "login";
export const checkAuthPath = "checkauth";
export const deliveryType = "delivery";
export const takeatliveType = "take at live";

export const requestBody = (body) => {
    return {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("token") },
        body: JSON.stringify(body)
    };
}
export const requestHeaderOnly = () => {
    return {
        headers: { 'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("token") }
    };
}
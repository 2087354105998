import { NavLink, useNavigate } from "react-router-dom";
import "./top_menu.scss";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import useWindowDimensions from "../tools/window_resolution";

function TopMenu(props){

    const navigateTo = useNavigate();

    const screenheight = useWindowDimensions().height;
    const initialwidth = useWindowDimensions().width
    const screenwidth = initialwidth < 375 ? 375 : initialwidth;

    const [showMenu, setShowMenu] = useState(false);

    const menulinks = [
        {
            name:"HOME",
            link:"/"
        },
        {
            name:"NEWS",
            link:"/news"
        },
        {
            name:"LIVE",
            link:"/live"
        },
        {
            name:"MUSIC",
            link:"/music"
        },
        {
            name:"MERCH",
            link:"/merch"
        },
        {
            name:"BIOGRAPHY",
            link:"/bio"
        },
        {
            name:"CONTACT",
            link:"/contact"
        },
    ];

    const listyle = {
        paddingTop: "10px",
        paddingBottom: "10px",
        fontSize: "18px"
    };
    
    const linkstyle = {
        color:"white",
    };

    const navigate = (link) => {
        navigateTo(link);
    }
    
    const listItems = menulinks.map(item => 
        <li key={item.name} style={listyle} onClick={() => navigate(item.link)}>
            {item.name}
        </li>);

    const buttonstyle ={
        color:"white",
        backgroundColor:"black",
        marginLeft:"-180px",
        width:"180px",
        height:"30px",
        zIndex: "3",
        textAlign: "center"
    };

    const menustyle = {
        display:!props.showMenu ? "none" : "",
        marginLeft: props.showMenu ? "-180px" : screenwidth<650 ? "-70px" : "-180px",
        position: "absolute",
        marginTop:"-15px",
        backgroundColor: props.showMenu ?"black" : "rgb(44 44 44)",
        width: props.showMenu ? "180px" : screenwidth<650 ? "60px" : "140px",
        borderRadius: props.showMenu ? "2px" : "4px",
        height: props.showMenu ? "470px" : "25px",
        transition: "height 0.3s",
        color:"white",
        textAlign:"center",
        fontSize: screenwidth<650 ? "15px" : "20px",
        paddingTop: screenwidth<650 ? "5px" : "5px",
       // visibility: showMenu ? "visible" : "hidden"
    };

    const menuContentStyle = {
        visibility: props.showMenu ? "visible" : "hidden",
        opacity: "2"
    }

    const burger = {
        width:"20px",
        display: props.showMenu ? "none" : ""
    }

    const cross = {
        width:"25px",        
        float:"right",
        padding:"10px",
        paddingRight:"12px",
        display: props.showMenu ? "" : "none" ,
        cursor:"pointer"
    }

    const handlogo = {
        backgroundImage: "url(hand_logo.png)",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",   
        width: "50px",
        height: "50px",
        display: "flex",
        justifyContent: "center",
        marginTop: "50px",
        marginLeft:"68px",
        zIndex:"4"     
    }

    return (
        <div>
            <div style={menustyle}>
                <img src="cross.svg" onClick={()=> props.closeMenu(false)} style={cross}/>                
                <img src="menu_icon_b.svg" style={burger}/>
                <div style={menuContentStyle}>
                    <div style={handlogo}></div>
                    <ul>
                        {listItems}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default TopMenu;